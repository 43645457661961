<template>
  <div>
    <b-sidebar
      id="sidebar-customer"
      v-model="showModal"
      backdrop
      shadow
      backdrop-variant="dark"
      right
      aria-labelledby="sidebar-customer-title"
      @hidden="hide()"
      no-header
    >
      <div class="sidebar-content">
        <div class="d-flex justify-content-between align-items-center p-3">
          <label class="header-text">Customer</label>
          <div class="pointer" @click="hide()">
            <b-icon icon="x-circle-fill"></b-icon>
          </div>
        </div>
        <div class="d-block">
          <div v-if="isLoading" class="loading-panel">
            <OtherLoading />
          </div>
          <div v-else-if="isAddOptionalTelephone" class="register-box p-3">
            <b-row no-gutters>
              <b-col cols="7" class="pr-3">
                <InputText
                  type="number"
                  textFloat=" "
                  v-model="telList[0].tel"
                  placeholder="เบอร์โทรศัพท์สำรอง"
                  :isValidate="$v.telList.$each[0].tel.$error"
                  :v="$v.telList.$each[0].tel"
                >
                </InputText>
              </b-col>
              <b-col cols="3">
                <InputText
                  type="number"
                  textFloat=" "
                  placeholder="เบอร์ต่อ"
                  v-model="telList[0].ext"
                  :disabled="!telList[0].tel"
                ></InputText>
              </b-col>
              <b-col
                cols="2"
                class="w-100 text-center d-flex justify-content-center align-items-center mt-4"
              >
                <font-awesome-icon
                  icon="trash-alt"
                  title="delete-btn"
                  size="lg"
                  class="pointer text-primary icon-delete"
                  @click.stop="deleteTel(0)"
                />
              </b-col>
              <template v-for="(item, index) in telList" v-if="index != 0">
                <b-col cols="7" class="pr-3">
                  <InputText
                    type="number"
                    textFloat=" "
                    v-model="item.tel"
                    placeholder="เบอร์โทรศัพท์สำรอง"
                    :isValidate="$v.telList.$each[index].tel.$error"
                    :v="$v.telList.$each[index].tel"
                  >
                  </InputText>
                </b-col>
                <b-col cols="3">
                  <InputText
                    type="number"
                    textFloat=" "
                    placeholder="เบอร์ต่อ"
                    v-model="item.ext"
                    :disabled="!item.tel"
                  ></InputText>
                </b-col>
                <b-col
                  cols="2"
                  class="w-100 text-center d-flex justify-content-center align-items-center mt-4"
                >
                  <font-awesome-icon
                    icon="trash-alt"
                    title="delete-btn"
                    size="lg"
                    class="pointer text-primary icon-delete"
                    @click.stop="deleteTel(index)"
                  />
                </b-col>
              </template>
            </b-row>
            <div class="d-flex justify-content-center">
              <b-button
                variant="primary-color"
                class="btn-modal btn-hover w-100 mt-3"
                @click="addOptinalTel"
                :disabled="
                  isLoading ||
                  (telList.length == limitOptionalTel &&
                    limitOptionalTel !== '')
                "
              >
                เพิ่มเบอร์โทรศัพท์สำรอง ( {{ telList.length }} /
                {{ limitOptionalTel }} )
              </b-button>
            </div>
          </div>
          <RegisterFields
            v-else
            @AddOptionalTelephone="AddOptionalTelephone"
            ref="registerFields"
            isMobile
            :form="form"
            isEdit
            @success="handleSuccess"
            :fields="fields"
            :dynamicField="dynamicField"
          />
        </div>
      </div>

      <template #footer>
        <div
          class="d-flex justify-content-between w-100 my-0 px-3 pb-3"
          v-if="isAddOptionalTelephone"
        >
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 mr-1"
            @click="closeOptionalPhone"
            :disabled="isLoading"
          >
            ย้อนกลับ
          </b-button>
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 ml-1"
            @click="saveOptionalPhone"
            :disabled="isLoading"
          >
            บันทึก
          </b-button>
        </div>
        <div class="d-flex justify-content-between w-100 my-0 px-3 pb-3" v-else>
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 mr-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 ml-1"
            @click="save"
            :disabled="isLoading"
          >
            แก้ไข
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import RegisterFields from "@/components/field/RegisterFields.vue";
import OtherLoading from "@/components/loading/OtherLoading";
import { mapGetters } from "vuex";
import { minLength, maxLength } from "vuelidate/lib/validators";

export default {
  components: {
    InputText,
    RegisterFields,
    OtherLoading,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: {},
      now: null,
      fields: [],
      c: [],
      dynamicField: [],
      isAddOptionalTelephone: false,
      limitOptionalTel: null,
      telList: [],
    };
  },
  validations() {
    return {
      telList: {
        $each: {
          tel: {
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
        },
      },
    };
  },

  created() {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
    this.limitOptionalTel = this.$store.state.limit_secondary_telephone;
  },

  computed: {
    ...mapGetters({
      mainLoading: "getMainLoading",
      dateTimeFix: "getDateTimeFix",
      branchId: "getBranchId",
    }),
  },
  watch: {
  telList: {
    handler(newVal) {
      newVal.forEach((item) => {
        if (!item.tel) {
          item.ext = '';
        }
      });
    },
    deep: true
  }
},

  methods: {
    async show(user) {
      this.isAddOptionalTelephone = false;
      this.telList = [] 
      this.telList.push({ tel: "", ext: "" });
      this.form = JSON.parse(JSON.stringify(user));
      await this.$store.dispatch("setMainLoading", false);
      this.showModal = true;
      this.isLoading = true;

      await this.getField();

      if (this.form.optional_telephone) {
        this.fixTelList();
      }
      this.isLoading = false;
    },
    async getField() {
      const data = await this.$axios.get(
        `${this.$baseUrl}/customer/registerform/1`
      );

      if (data.result === 1) {
        this.fields = data.detail.result.fields.filter(
          (el) => el.show_on_edit_profile == 1
        );

        for (const field of data.detail.result.custom_field) {
          let form = this.form.custom_field.find((el) => el.id == field.id);

          if (form) {
            let value = form.user_answer.map((el) => el.answer);
            if (value.length == 0)
              value = field.field_choices
                .filter((el) => el.isDefault == 1)
                .map((el) => el.name);
            if (field.field_type_id == 2) {
              field.user_answer = value;
            } else {
              field.user_answer = value.toString();
            }
          }
        }

        this.dynamicField = data.detail.result.custom_field.filter(
          (el) => el.show_on_edit_profile == 1
        );
      }
    },
    handleSuccess() {
      this.hide();
    },
    hide() {
      this.showModal = false;
      this.$emit("success");
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleEnter(isPost) {
      if (isPost) {
        this.$refs.postNum.$el.querySelector("input").blur();
      } else {
        this.$refs.lineId.$el.querySelector("input").blur();
      }
    },

    async save() {
      this.isLoading = true;
      this.$refs.registerFields.save();

      this.isLoading = false;
    },
    fixTelList() {
      this.telList = this.form.optional_telephone.split(",").map((tel) => {
        const [number, ext] = tel.split("-");
        return { tel: number, ext: ext || "" };
      });
    },
    addOptinalTel() {
      if (
        !this.limitOptionalTel ||
        this.telList.length < this.limitOptionalTel
      ) {
        this.telList.push({ tel: "", ext: "" });
      }
    },
    AddOptionalTelephone() {
      this.isAddOptionalTelephone = true;
    },
    closeOptionalPhone() {
      this.telList = [];
      this.$v.$reset();
      this.fixTelList();
      this.isAddOptionalTelephone = false;
    },
    saveOptionalPhone() {
      this.$v.telList.$touch();
      if (!this.$v.$error) {
        this.form.optional_telephone = this.telList
          .map((item) => (item.ext ? `${item.tel}-${item.ext}` : item.tel))
          .join(",");

        this.isAddOptionalTelephone = false;
      }
    },
    deleteTel(index) {
      if (index == 0) {
        this.telList[0].tel = "";
        this.telList[0].ext = "";
        return;
      }
      this.telList.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .min-w-75 {
  min-width: 75% !important;
}

::v-deep .nav-tabs {
  .nav-link {
    font-size: 14px !important;
  }
}

::v-deep .b-sidebar {
  width: 90vw;
}
</style>
